<form [formGroup]="form" (ngSubmit)="onSubmit(formModel)" [class]="class" [id]="formId">
  <formly-form [form]="form" [options]="options" [fields]="fields" [model]="formModel" (keydown.enter)="onEnter(formModel, $event)"></formly-form>
  <ion-row *ngIf="description" >
    <ion-col>
      <ion-text color="medium" class="ion-text-left">
        <p class="small">{{description}}</p>
      </ion-text>
    </ion-col>
  </ion-row>
  <ion-button [id]="submitButtonId" *ngIf="submitButton" 
    type="submit" [color]="submitButtonText==='Update'?'warning':'success'" 
    [disabled]="!form.valid || disabled || submitDisabled" class="btn btn-default">
    <div class="button-text">
      {{submitButtonText}}
    </div>

    <div class="spinner-overlay" *ngIf="spinner">
        <ion-spinner slot="end"></ion-spinner>
    </div>
  </ion-button>
</form>