import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CustomFormModule } from '@app/global/modules/custom-form/custom-form.module';
import { AlertsService } from '@app/global/services/alerts.service';
import { IonicModule } from '@ionic/angular';
import { materialField, mmidField, projectField, vendorField } from '@app/global/form-fields/stock-checker';
import { ProjectService } from '@app/global/services/project.service';
import { MaterialService } from '@app/global/services/material.service';
import { FormlyFieldsService } from '@app/global/services/formly-fields.service';
import { RequestsService } from '@app/global/services/requests.service';
import { StockCheckerFormModel } from '@app/global/models/formly-forms';
import { GeneralService } from '@app/global/services/general.service';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, CustomFormModule],
  selector: 'app-stock-checker',
  templateUrl: './stock-checker.component.html',
  styleUrls: ['./stock-checker.component.scss'],
})
export class StockCheckerComponent  implements OnInit {
  formModel: any = {};
  formLoader: boolean = true;
  formData: any = [];
  formSubmitInProgress: boolean = false;
  stock: any = {};
  dataLoaded: boolean = false;
  projectSelected: boolean = false;
  selectedMaterial: any = null;
  selectedMmid: any = null;
  selectedVendor: any = null;
  selectedProject: any = null;
  materialList: any = [];
  mmidList: any = [];
  vendorList: any = [];
  projectList: any = [];
  optionsChanged: boolean = false;
  constructor(
    private alerts: AlertsService,
    private projectServ: ProjectService,
    private matServ: MaterialService,
    private formServ: FormlyFieldsService,
    private reqServ: RequestsService,
    private general: GeneralService,
  ) { }

  /**
   * Update the form model with options for the select fields.
   * If this is an edit instance, prefill the form with data.
   */
  async ngOnInit() {
    this.updateFormModel();
  }

  private updateFormModel(){
    const select_projects = this.projectServ.getOngoingOrNotStartedProjects();
    const select_materials = this.matServ.getAllApprovedMaterials();
    Promise.all([select_projects, select_materials]).then((values:any) => {
      const _projectField:any = this.formServ.updateDataFieldOptions(values[0], projectField, false);

      const _materialField:any = this.formServ.updateDataFieldOptions(values[1], materialField, true);

      mmidField.hooks = {
        onInit: (_mmidField: any) => {
          _mmidField.formControl.valueChanges.subscribe((selectedMmid: any) => {
            this.reqServ.onMmidFieldChange(_mmidField);
            this.optionsChanged = true;
          });
        }
      }

      vendorField.hooks = {
        onInit: (_vendorField: any) => {
          _vendorField.formControl.valueChanges.subscribe((selectedVendor: any) => {
            this.reqServ.onVendorFieldChange(_vendorField);
            this.mmidList = this.reqServ.mmidList;
            this.optionsChanged = true;
          });
        }
      }

      _materialField.hooks = {
        onInit: (_materialField: any) => {
          _materialField.formControl.valueChanges.subscribe((selectedMaterialId: any) => {
            this.reqServ.onMaterialFieldChange(_materialField);
            this.vendorList = this.reqServ.vendorList;
            this.optionsChanged = true;
          });
        }
      }

      _projectField.hooks = {
        onInit: (_projectField: any) => {
          _projectField.formControl.valueChanges.subscribe((selectedProjectId: any) => {
            this.optionsChanged = true;
          });
        }
      }

      this.formData = [
        _materialField,
        mmidField,
        vendorField,
        _projectField,
      ];

      // update lists
      this.materialList = values[1].map((material: any) => {
        return {
          value: material.id,
          label: material.name,
        }
      });
      this.projectList = values[0].map((project: any) => {
        return {
          value: project.id,
          label: project.name,
        }
      });
    })
    .catch((error) => {
      this.alerts.dismissModal(true, 'error');
    })
    .finally(() => {
      this.formLoader = false;
    });

  }

  getStockData(e:StockCheckerFormModel){
    this.formSubmitInProgress = true;
    this.general.getStockData(e).then((res: any) => {
      this.stock = res;
      this.alerts.showToast('Stock data fetched successfully');
      this.dataLoaded = true;
      this.projectSelected = !!e.project;

      this.selectedMaterial = this.materialList.find((material: any) => material.value === e.material);
      this.selectedMmid = this.mmidList.find((mmid: any) => mmid.value === e.mmid);
      this.selectedVendor = this.vendorList.find((vendor: any) => vendor.value === e.vendor);
      this.selectedProject = this.projectList.find((project: any) => project.value === e.project);
      this.optionsChanged = false;
    })
    .catch((err: any) => {
      this.alerts.showApiErrorToast(err, 'Error creating material request');
    })
    .finally(() => {
      this.formSubmitInProgress = false;

    });
  }

  closeModal(type?: string) {
    this.alerts.dismissModal(true, type ? type : null);
  }
}
