export enum ApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum InventoryOutRequestItemStatus {
  PENDING = 'Pending Approval',
  ISSUED = 'Issued',
  REJECTED = 'Rejected',
  NOT_ISSUED = 'Not Issued'
}
export enum ReleaseStatus {
  RELEASED = 'RELEASED',
  NOT_RELEASED = 'NOT_RELEASED',
  CANCELED = 'CANCELED',
}
export enum DeliveryStatus {
  PENDING = 'PENDING',
  PARTIAL = 'PARTIAL',
  DELIVERED = 'DELIVERED'
}
export enum ServiceRequestType {
  MATERIAL = 'MATERIAL',
  PART = 'PART',
}
export enum ApprovalType {
  ADD_EMPLOYEE = 'ADD_EMPLOYEE',
  ADD_MANUFACTURER_TO_MATERIAL = 'ADD_MANUFACTURER_TO_MATERIAL',
  BAN_VENDOR = 'BAN_VENDOR',
  BAN_MMID = 'BAN_MMID',
  BAN_VENDOR_MMID = 'BAN_VENDOR_MMID',
  INVENTORY_IN = 'INVENTORY_IN',
  MATERIAL_BATCH = 'MATERIAL_BATCH',
  INVENTORY_OUT = 'INVENTORY_OUT',
  INVENTORY_OUT_REQUEST = 'INVENTORY_OUT_REQUEST',
  MATERIAL = 'MATERIAL',
  MMID = 'MMID',
  MATERIAL_REQUEST = 'MATERIAL_REQUEST',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  NC = 'NC',
  LEAVE = 'LEAVE',
  PAYMENT = 'PAYMENT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  QUOTE = 'QUOTE',
  RESERVE_INVENTORY = 'RESERVE_INVENTORY',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VENDOR = 'VENDOR',
  DEFAULT = 'DEFAULT',
  PART = 'PART',
  SERVICE_REQUEST = 'SERVICE_REQUEST',
  PART_BATCH = 'PART_BATCH',
}

export enum Department {
  PRODUCTION_SERVICES = 'Production: Services',
  PRODUCTION_MACHINES = 'Production: Machines',
  PRODUCTION_AM = 'Production: AM',
  QUALITY = 'Quality',
  PROJECTS = 'Projects',
  FINANCE = 'Finance',
  HR = 'HR',
  ADMIN = 'Admin',
  INVENTORY = 'Inventory',
  BUSINESS = 'Business Development',
  MARKETING = 'Marketing',
  RNDSOFTWARE = 'R&D: Software',
  RNDMECHANICAL = 'RND: Mechanical',
  RNDELECTRICAL = 'RND: Electrical',
  RNDTESTING = 'RND: Testing',
  HOUSEKEEPING = 'Housekeeping',
  FACILITIES = 'Facilities',
  OTHER = 'Other',
}

export enum DataModels {
  MATERIAL = 'material',
  MMID = 'mmid',
  MANUFACTURER = 'manufacturer',
  VENDOR = 'vendor',
  PROFILE = 'profile',
  TICKET = 'ticket',
  WORKTIME = 'worktime',
  LEAVE = 'leave',
  LEAVEBALANCE = 'leavebalance',
  CLIENT = 'client',
  PROJECT = 'project',
  DELIVERABLE = 'deliverable',
  EVENT = 'event',
  APPROVER = 'approver',
  ONBOARDING = 'onboarding',
  MATERIAL_CATEGORY = 'materialcategory',
  MATERIALBATCH = 'materialbatch',
  NOTIFICATION = 'notification',
  INVENTORYOUTREQUEST = 'inventoryoutrequest',
  INVENTORYOUTREQUESTITEM = 'inventoryoutrequestitem',
  INVENTORYOUT = 'inventoryout',
  INVENTORYIN = 'inventoryin',
  RESERVEDINVENTORY = 'reservedinventory',
  INVENTORYRESERVEDREQUEST = 'inventoryreservedrequest',
  INVENTORYRESERVEDREQUESTITEM = 'inventoryreservedrequestitem',
  CONTACTPERSON = 'contactperson',
  APPROVAL = 'approval',
  COST = 'cost',
  DOCUMENT = 'document',
  PURCHASEREQUEST = 'purchaserequest',
  PURCHASEREQUESTITEM = 'purchaserequestitem',
  MATERIALREQUEST = 'materialrequest',
  MATERIALREQUESTITEM = 'materialrequestitem',
  PURCHASEITEM = 'purchaseitem',
  PURCHASEORDER = 'purchaseorder',
  QUOTE = 'quote',
  PAYMENT = 'payment',
  QUOTEGROUP = 'quotegroup',
  USER = 'user',
  VENDORLINEITEM = 'vendorlineitem',
  MATERIALLINEITEM = 'materiallineitem',
  MMIDLINEITEM = 'mmidlineitem',
  MANUFACTURERLINEITEM = 'manufacturerlineitem',
  EXTRASETTINGS = 'extrasettings',
  HSNCODE = 'hsncode',
  PART = 'part',
  SERVICEREQUEST = 'servicerequest',
  PARTBATCH = 'partbatch',
}
export enum LeaveType {
  CASUAL = 'CASUAL',
  SICK = 'SICK',
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  MATERNITY = 'MATERNITY',
  PATERNITY = 'PATERNITY',
  WORK_FROM_HOME = 'WORK_FROM_HOME',
  OTHER = 'OTHER',
  COMPENSATORY = 'COMPENSATORY',
}

export enum Mmid_availability {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  RARE = 'RARE',
}
export enum NotificationType {
  ADD_EMPLOYEE = 'ADD_EMPLOYEE',
  ADD_MANUFACTURER_TO_MATERIAL = 'ADD_MANUFACTURER_TO_MATERIAL',
  BAN_VENDOR = 'BAN_VENDOR',
  BAN_MMID = 'BAN_MMID',
  BAN_VENDOR_MMID = 'BAN_VENDOR_MMID',
  DEFAULT = 'DEFAULT',
  INVENTORY_IN = 'INVENTORY_IN',
  MATERIAL_BATCH = 'MATERIAL_BATCH',
  INVENTORY_OUT = 'INVENTORY_OUT',
  MATERIAL = 'MATERIAL',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  NC = 'NC',
  LEAVE = 'LEAVE',
  PAYMENT = 'PAYMENT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  QUOTE = 'QUOTE',
  RESERVE_INVENTORY = 'RESERVE_INVENTORY',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VENDOR = 'VENDOR',
  PAYMENT_IMMINENT = 'PAYMENT_IMMINENT',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE',
  PART = 'PART',
  SERVICE_REQUEST = 'SERVICE_REQUEST',
  PART_BATCH = 'PART_BATCH',
}

export enum Permissions {
  READ = 'read',
  CREATE = 'create',
  ALL = 'all',
}

export enum PermissionScope {
  OWN = 'own',
  TEAM = 'team',
  ALL = 'all',
}

export enum ExtraServerSettingKeys{
  MAINTENANCE = 'MAINTENANCE',
  AUTO_APPROVAL = 'AUTO_APPROVAL',
  AUTO_APPROVAL_MODELS = 'AUTO_APPROVAL_MODELS',
  GENERAL_NOTICE = 'GENERAL_NOTICE',
  USER_NOTICE = 'USER_NOTICE',
  MAT_REQ_EDIT = 'MAT_REQ_EDIT',
}
export enum PaymentStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  OVERDUE = 'OVERDUE',
  ADVANCE_PAID = 'ADVANCE_PAID',
  ADVANCE_PENDING = 'ADVANCE_PENDING',
  ADVANCE_OVERDUE = 'ADVANCE_OVERDUE',
  PAID_LATE = 'PAID_LATE',
}
export enum PoPaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  ADVANCE_PAID = 'ADVANCE_PAID',
  PAID_LATE = 'PAID_LATE',
}
export enum ShipmentPreference {
  LOCAL = 'Via Local Transport',
  COURIER = 'Courier',
  SELF = 'Direct collect',
}
export enum ProjectUserType {
  PROJECT_MANAGER = 'project_manager',
  PROJECT_LEAD_EXEC = 'project_lead_exec',
  PROJECT_EXEC = 'project_exec',
  PRODUCTION_LEAD = 'production_lead',
  PRODUCTION_EXEC = 'production_exec',
  QUALITY_LEAD = 'quality_lead',
  QUALITY_EXEC = 'quality_exec',
  SUPPLY_CHAIN_LEAD = 'supply_chain_lead',
  SUPPLY_CHAIN_EXEC = 'supply_chain_exec',
  INVENTORY_LEAD = 'inventory_lead',
  INVENTORY_EXEC = 'inventory_exec',
}

export enum ProjectType {
  SERVICE = 'SERVICE',
  MACHINE = 'MACHINE',
  RND = 'RND',
  GENERAL = 'GENERAL',
}

export enum MaterialRequestStatus {
  OPEN = 'OPEN',
  AVAILABLE = 'AVAILABLE',
  PROCESSED = 'PROCESSED',
  CLOSED = 'CLOSED',
}

export enum ProjectStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export enum BatchQcStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  ON_HOLD = 'ON_HOLD',
  WIP = 'WIP',
  ISSUE = 'ISSUE'
}

export enum PaymentMode {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  NEFT = 'NEFT',
  RTGS = 'RTGS',
  IMPS = 'IMPS',
  UPI = 'UPI',
}

export enum PaymentType {
  ADVANCE = 'ADVANCE',
  BALANCE = 'BALANCE',
}