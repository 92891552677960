import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, PopoverController } from '@ionic/angular';
import { DropdownSearchPipe } from '@app/global/pipes/dropdown-search'; 
import { TippyDirective } from '@ngneat/helipopper';
import { CustomSelectOption } from '@app/global/models/basic';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, DropdownSearchPipe, TippyDirective],
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
  @Input() title: string = ""
  @Input() items: CustomSelectOption[] = [] as any;
  @Input() selected: string = "";
  @Input() limitResults: number = 0; // To limit results in dropdown, add `limitResults` attribute in formly field props
  itemsVisible: any = [];
  hasHiddenResults: boolean = false;

  constructor(
    private popoverController: PopoverController) { }

  ngOnInit() {
    if(this.limitResults && this.items.length > this.limitResults){
      this.hasHiddenResults = true;
      
      if (this.selected){
        this.itemsVisible = this.items.slice(0, this.limitResults-1);
        const selectedItem = this.items.find((item: any) => item.label.toLowerCase() === this.selected.toLowerCase());
        if(selectedItem){
          this.itemsVisible.unshift(selectedItem);
        }
      }
      else{
        this.itemsVisible = this.items.slice(0, this.limitResults);
      }

      this.itemsVisible.push({
        label: this.items.length - this.limitResults + ' more ...', 
        value: 'more',
        disabled: true,
        subLabel: 'Search to see relevant results'
      });
    }
    else{
      this.hasHiddenResults = false;
      this.itemsVisible = this.items;
    }
  }

  selectItem(item: any) {
    // If the item is already selected, close the popover
    if(this.selected && this.selected.toLowerCase()===item.label.toLowerCase()){
      this.popoverController.dismiss();
      return;
    }
    this.popoverController.dismiss({
      'selectedItem': item
    });
  }

  filterResults(e:any) {
    this.itemsVisible = this.items.filter((item: any) => {
      return item.label.toLowerCase().includes(e.detail.value.toLowerCase()) 
        || item.subLabel.toLowerCase().includes(e.detail.value.toLowerCase());
    })
  }
}
