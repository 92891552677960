import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import { MatCat, MatCatApiData, Material, MaterialDetail } from '@app/global/models/basic';
import { CrudService } from '@app/global/services/crud.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService,
  ) {
    // this.getMaterialCategories();
  }

  getMaterialUnits() {
    return new Promise((resolve, reject) => {
      this.crud.get('units').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getMaterialDetailById(materialId: number): Promise<MaterialDetail> {
    return new Promise((resolve, reject) => {
      this.crud.get('materials', materialId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getMaterialLIDetailById(materialLIId: number): Promise<MaterialDetail> {
    return new Promise((resolve, reject) => {
      this.crud.get('materiallineitems', materialLIId).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getAllMaterials() {
    return new Promise((resolve, reject) => {
      this.crud.list('materials').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getAllApprovedMaterials() {
    return new Promise((resolve, reject) => {
      this.crud.get('materials/summary', '', {approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getApprovedMaterialsByManf(id: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('materials/summary', '', {manf: id, approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getMaterials(page: number,
    searchText?: string,
    sortText?: string,
    filters?: any[]) {
    return new Promise((resolve, reject) => {
      let params:any = {
        ordering: sortText,
        search: searchText 
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          params[filter.key] = filter.value;
        });
      }
      this.crud.list('materials', page, params).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getApprovedMaterialsByCategoryId(id: number) {
    return new Promise((resolve, reject) => {
      this.crud.get('materials', '', {category: id, approved: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getMaterialCategories(): Promise<MatCat[]> {
    return new Promise((resolve, reject) => {
      this.crud.get('matcat', '', {ordering: 'id'}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  createMaterialCategory(category: MatCatApiData) {
    return new Promise((resolve, reject) => {
      this.crud.post('matcat', category).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  removeMaterialCategory(category: any) {
    return new Promise((resolve, reject) => {
      this.crud.patch('matcat', category.id, {is_deleted: true}).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  createMaterial(material: Material) {
    return new Promise((resolve, reject) => {
      this.crud.post('materials', material).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  updateMaterial(material: Material, id: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('materials', id, material).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  addExistingManufacturerToMaterial(matId: number, manfId: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('materials', matId, {manf: manfId, action: 'add_manf'}).then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getAllMaterialLineItems() {
    return new Promise((resolve, reject) => {
      this.crud.list('materiallineitems').then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getMaterialBatchList(materialId: number, mmidId: number | undefined, vendorId: number | undefined, available: boolean = false) {
    return new Promise((resolve, reject) => {
      const options:any = {
        material: materialId,
        mmid: mmidId,
        vendor: vendorId,
        available: available,
      }
      this.crud.list('materialbatch', undefined, options).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * Get all material requested in a project
   * @param projectId 
   * @returns 
   */
  getAllProjectApprovedMaterials(projectId: number) {
    return new Promise((resolve, reject) => {
      this.crud.get(`projects/${projectId}/materials`).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
