import { Injectable } from '@angular/core';
import { SharedVarsService } from '@app/global/services/shared-vars.service';
import {
  InventoryOutRequest,
  Material,
} from '@app/global/models/basic';
import { CrudService } from '@app/global/services/crud.service';
import { FormlyFieldsService } from '@app/global/services/formly-fields.service';
import { MaterialService } from '@app/global/services/material.service';
import { MmidService } from '@app/global/services/mmid.service';
import { VendorService } from '@app/global/services/vendor.service';
import { AlertsService } from '@app/global/services/alerts.service';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  vendorList: any = [];
  mmidList: any = [];
  allVendorList: any = [];
  allMmidList: any = [];
  refreshApiRequests: any = [];
  invOutParamValues: any = {
    material: null,
    mmid: null,
    vendor: null,
    project: null,
  };
  constructor(
    private sharedVars: SharedVarsService,
    private crud: CrudService,
    private formServ: FormlyFieldsService,
    private matServ: MaterialService,
    private mmidServ: MmidService,
    private vendorServ: VendorService,
    private alerts: AlertsService
  ) {}

  createInvReserveRequest(request: InventoryOutRequest) {
    return new Promise((resolve, reject) => {
      this.crud.post('reservedrequests', request).then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  updateInvReserveRequest(requestId: number, request: InventoryOutRequest) {
    return new Promise((resolve, reject) => {
      this.crud.put('reservedrequests', requestId, request).then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  cancelInvReserveRequest(requestId: number) {
    return new Promise((resolve, reject) => {
      this.crud.patch('reservedrequests', requestId, {is_canceled: true}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  /**
   * When the material is changed, reset the mmid and vendor fields.
   * Then fetch the mmids and vendors based on the selected material.
   * Update the mmid and vendor fields with the new options.
   * @param materialField 
   * @param materialId 
   */
  onMaterialFieldChange(materialField: any, superParent: boolean = false) {
    let mmidField: any;
    let vendorField: any;
    if(superParent){
      mmidField = this.formServ.findDataFieldInSuperParent(materialField, 'mmid');
      vendorField = this.formServ.findDataFieldInSuperParent(materialField, 'vendor');
    }
    else{
      mmidField = this.formServ.findDataFieldInParent(materialField, 'mmid');
      vendorField = this.formServ.findDataFieldInParent(materialField, 'vendor');
    }
    vendorField.formControl.setValue(null);
    mmidField.formControl.setValue(null);

    const materialId = materialField.formControl.value;
    this.refreshMmidOptions(mmidField, materialId, 0);
    this.refreshVendorOptions(vendorField, materialId, 0);
    this.enableDisabledFields(materialField, mmidField, vendorField);
  }
  /**
   * When the mmid is changed, fetch the vendors based on the selected material and mmid.
   * Update the vendor field with the new options.
   * @param mmidField 
   */
  onMmidFieldChange(mmidField: any, superParent: boolean = false) {
    let materialField: any;
    let vendorField = this.formServ.findDataFieldInParent(mmidField, 'vendor');
    if(superParent){
      materialField = this.formServ.findDataFieldInSuperParent(mmidField, 'material');
    }
    else{
      materialField = this.formServ.findDataFieldInParent(mmidField, 'material');
    }

    const materialId = materialField.formControl.value;
    if (materialId) {
      this.refreshVendorOptions(vendorField, materialId, mmidField.formControl.value);
    } 
  }
  /**
   * When the vendor is changed, fetch the mmids based on the selected material and vendor.
   * Update the mmid field with the new options.
   * @param vendorField 
   */
  onVendorFieldChange(vendorField: any, superParent: boolean = false) {
    let materialField: any;
    let mmidField = this.formServ.findDataFieldInParent(vendorField, 'mmid');
    if (superParent) {
      materialField = this.formServ.findDataFieldInSuperParent(vendorField, 'material');
    }
    else {
      materialField = this.formServ.findDataFieldInParent(vendorField, 'material');
    }
    const materialId = materialField.formControl.value;
    if (materialId) {
      this.refreshMmidOptions(mmidField, materialId, vendorField.formControl.value);
    }
  }
  /**
   * Used in Edit condition, to update the mmid and vendor field options
   * based on the selected material, mmid and vendor. This takes care of
   * each item's mmid and vendor fields separately.
   * @param materialField 
   * @param superParent 
   */
  updateAllFields(materialField: any, superParent: boolean = false) {
    let mmidField: any;
    let vendorField: any;
    if(superParent){
      mmidField = this.formServ.findDataFieldInSuperParent(materialField, 'mmid');
      vendorField = this.formServ.findDataFieldInSuperParent(materialField, 'vendor');
    }
    else{
      mmidField = this.formServ.findDataFieldInParent(materialField, 'mmid');
      vendorField = this.formServ.findDataFieldInParent(materialField, 'vendor');
    }
    const materialId = materialField.formControl.value;
    const vendorId = vendorField.formControl?.value;
    const mmidId = mmidField.formControl?.value;
    this.refreshMmidOptions(mmidField, materialId, vendorId);
    this.refreshVendorOptions(vendorField, materialId, mmidId);
  }
  /**
   * Refresh the vendor options based on the selected material and mmid.
   * @param vendorField 
   * @param materialId 
   * @param mmidId 
   */
  refreshVendorOptions(vendorField: any, materialId: number, mmidId: number) {
    if(this.refreshApiRequests.includes('vendor')){
      return;
    }
    this.refreshApiRequests.push('vendor');
    this.vendorServ.getVendorsByMaterial(materialId, mmidId, {approved: true}).then((vendorList:any) => {
      this.vendorList = vendorList.map((vendor: any) => {
        return {name: vendor.name, id: vendor.id};
      });
      this.allVendorList.push(...vendorList);
      vendorField = this.formServ.updateDataFieldOptions(vendorList, vendorField);
      this.refreshApiRequests.splice(this.refreshApiRequests.indexOf('vendor'), 1);
    })
    .catch((err) => {
      this.refreshApiRequests.splice(this.refreshApiRequests.indexOf('vendor'), 1);
      this.alerts.showApiErrorToast(err);
    });
  }
  /**
   * Refresh the mmid options based on the selected material and vendor.
   * @param mmidField 
   * @param materialId 
   * @param vendorId 
   */
  refreshMmidOptions(mmidField: any, materialId: number, vendorId: number) {
    if(this.refreshApiRequests.includes('mmid')){
      return;
    }
    this.refreshApiRequests.push('mmid');
    this.mmidServ.getMmidByMaterialAndVendor(materialId, vendorId, {approved: true}).then((mmidList:any) => {
      this.mmidList = mmidList.map((mmid: any) => {
        return {name: mmid.name, id: mmid.id};
      });
      this.allMmidList.push(...mmidList);
      mmidField = this.formServ.updateDataFieldOptions(mmidList, mmidField);
      this.refreshApiRequests.splice(this.refreshApiRequests.indexOf('mmid'), 1);
    })
    .catch((err) => {
      this.refreshApiRequests.splice(this.refreshApiRequests.indexOf('mmid'), 1);
      this.alerts.showApiErrorToast(err);
    });
  }
  updateQuantityWithUnit(all_materials: any, materialField: any, selectedMaterialId: any) {
    const selectedMaterialIndex = all_materials.findIndex((material: Material) => material.id === selectedMaterialId);
    if (selectedMaterialIndex !== -1) {
      const quantityField = this.formServ.findDataFieldInParent(materialField, 'quantity');
      quantityField.props.label =  `Quantity (${all_materials[selectedMaterialIndex].unit})`
    }
  }
  trackRequest(requestId: number, type: string) {
    return new Promise((resolve, reject) => {
      this.crud.get('track-request', requestId , {type: type}).then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  /**
   * When a request is edited, generate a list of all MMID objects 
   * from the API response to pre-populate the MMID field options 
   * in the form items. When the form is populated with actual 
   * values, one of these options will be shown as selected. Post that, 
   * the `updateAllFields` method will update each item's MMID options 
   * based on the selected material, mmid and vendor.
   */
  prePopulateMmidOptions(data:any, isLineItem: boolean = true) {
    return data.map((item: any) => {
      return {name: item.mmid?.name, id: isLineItem ? item.mmid?.source : item.mmid?.id};
    });
  }
  /**
   * When a request is edited, generate a list of all Vendor objects 
   * from the API response to pre-populate the Vendor field options 
   * in the form items. When the form is populated with actual 
   * values, one of these options will be shown as selected. Post that, 
   * the `updateAllFields` method will update each item's Vendor options 
   * based on the selected material, mmid and vendor.
   */
  prePopulateVendorOptions(data:any, isLineItem: boolean = true) {
    return data.map((item: any) => {
      return {name: item.vendor?.name, id: isLineItem ? item.vendor?.source : item.vendor?.id};
    });
  }
  /**
   * When a purchase request is created from a material request,
   * for each item in purchase request, pre-populate the material
   * field options with the material and alt_material from the
   * material request item.
   */
  prePopulateMaterialOptions(data:any, isLineItem: boolean = true) {
    return data.map((item: any) => {
      return {name: item.material?.name, id: isLineItem ? item.material?.source : item.material?.id};
    });
  }
  /**
   * When material request is converted to purchase request,
   * mmid and vendor fields are disabled if they have a value.
   * Then, if the material field is updated, the mmid and vendor
   * fields are enabled.
   * @param materialField 
   * @param mmidField 
   * @param vendorField 
   */
  enableDisabledFields(materialField: any, mmidField: any, vendorField: any) {
    if (materialField.formControl.value) {
      mmidField.formControl.enable();
      vendorField.formControl.enable();
    }
  }
  /**
   * When material request is converted to purchase request,
   * it is tricky to update each item's material field with
   * the material and alt_material from the material request item.
   * Hence, at first, each item's material field is updated with
   * the material (and alt_material if available) from all the items
   * using `prePopulateMaterialOptions`.
   * Post that, the `updateMaterialOptions` method is called to update
   * the material field with the actual material and alt_material from
   * the material request item.
   * If there is no alt_material, then the material field is disabled.
   * @param materialField 
   * @param material_request_items - All the items from the material request
   */
  updateMaterialOptions(materialField: any, material_request_items: any) {
    const currentValue = materialField.formControl.value;
    const material_request_item = material_request_items.find((item: any) => item.material.source === currentValue);
    const options = [{name: material_request_item.material.name, id: material_request_item.material.source}];
    // if (material_request_item.alt_material) {
    //   options.push({name: material_request_item.alt_material.name, id: material_request_item.alt_material.source});
    //   const hasAltMaterialField = this.formServ.findFormFieldInSuperParent(materialField, 'has_alt_material');
    //   hasAltMaterialField.formControl?.setValue('This item has alternate material option.');
    // }
    // else{
    materialField.formControl.disable();
    // }
    materialField = this.formServ.updateDataFieldOptions(options, materialField);
  }

}
