import { Component } from '@angular/core';
import { sideMenuPages } from './global/constants/page-vars';
import { AuthService } from './global/services/auth.service';
import { SharedVarsService } from './global/services/shared-vars.service';
import { GeneralService } from './global/services/general.service';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loader: any = {
    show: false,
    text: '',
  };
  public sideMenuPages = sideMenuPages;

  constructor(public auth: AuthService, 
    public sharedVars: SharedVarsService, 
    public generalServ: GeneralService,
    private menu: MenuController
    ) {
    this.loader = this.sharedVars.GLOBAL_LOADER;
    this.generalServ.getMetaData();
  }

  public keepOriginalOrder = (a: any, b: any) => a.key;


  toggleMenu(){
    this.menu.toggle();
  }
}
