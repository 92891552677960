import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { ServiceRequestsService } from '@app/global/services/service-requests.service';

@Component({
  selector: 'app-service-request-item-output',
  templateUrl: './service-request-item-output.component.html',
  styleUrls: ['./service-request-item-output.component.scss'],
})
export class ServiceRequestItemOutputComponent extends FieldArrayType implements OnInit, OnDestroy {

  constructor(
    public serviceRequestServ: ServiceRequestsService,
  ) { 
    super();
  }

  ngOnInit() {
    if(this.field.fieldGroup && this.field.fieldGroup.length==0){
      this.addItem(); // Add an initial item if the field group is empty
    }
  }

  ngOnDestroy() {}

  async addItem() {
    this.add();
  }
  removeField(itemIndex: number) {
    this.remove(itemIndex);
  }
}
