import { FormlyFieldConfig } from '@ngx-formly/core';

export const materialField: FormlyFieldConfig = {
  key: 'material',
  type: 'customselect',
  props: {
    required: true,
    label: 'Material',
    labelPosition: 'stacked',
    placeholder: 'Select Material',
    options: [],
  },
  className: 'custom-ion-input inline-select flex-1',
};
export const mmidField: FormlyFieldConfig = {
  key: 'mmid',
  type: 'customselect',
  props: {
    required: false,
    label: 'Mmid',
    labelPosition: 'stacked',
    placeholder: 'Select Mmid',
    options: [],
  },
  className: 'custom-ion-input inline-select flex-1',
};
export const vendorField: FormlyFieldConfig = {
  key: 'vendor',
  type: 'customselect',
  props: {
    required: false,
    label: 'Vendor',
    labelPosition: 'stacked',
    placeholder: 'Select Vendor',
    options: [],
  },
  className: 'custom-ion-input inline-select flex-1',
};
export const projectField: FormlyFieldConfig = {
  key: 'project',
  type: 'customselect',
  props: {
    required: false,
    label: 'Project',
    labelPosition: 'stacked',
    placeholder: 'Select Project',
    options: [],
  },
  className: 'custom-ion-input inline-select flex-1',
};
