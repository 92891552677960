import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { CustomFormComponent } from '@app/global/modules/custom-form/custom-form/custom-form.component';
import { IonicModule } from '@ionic/angular';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyModule } from '@ngx-formly/core';
import { OnboardingWrapperComponent } from '@app/global/components/onboarding-wrapper/onboarding-wrapper.component';
import { RequestLineItemComponent } from '@app/pages/inventory-out-requests/request-line-item/request-line-item.component';
import { CostWrapperComponent } from '@app/global/modules/custom-form/cost-wrapper/cost-wrapper.component';
import { QuoteLineItemComponent } from '@app/pages/quotes/quote-line-item/quote-line-item.component';
import { FileValueAccessor } from '../file-value-accessor';
import { FileTypeComponent } from '@app/global/modules/custom-form/file-type/file-type.component';
import { DocsWrapperComponent } from '@app/global/components/docs-wrapper/docs-wrapper.component';
import { PurchaseOrderItemComponent } from '@app/pages/purchase-orders/purchase-order-item/purchase-order-item.component';
import { InventoryInLineItemComponent } from '@app/pages/inventory-in/inventory-in-lineitem/inventory-in-lineitem.component';
import { InventoryOutLineItemComponent } from '@app/pages/inventory-out/inventory-out-lineitem/inventory-out-lineitem.component';
import { LeaveBalanceItemComponent } from '@app/pages/users/leave-balance-item/leave-balance-item.component';
import { ReservedInventoryItemComponent } from '@app/pages/reserved-inventory/reserved-inventory-item/reserved-inventory-item.component';
import { CustomSearchSelectComponent } from '@app/global/modules/custom-form/custom-search-select/custom-search-select.component';
import { PurchaseRequestItemComponent } from '@app/pages/purchase-requests/purchase-request-item/purchase-request-item.component';
import { ProjectPersonnelWrapperComponent } from '@app/pages/projects/project-personnel-wrapper/project-personnel-wrapper.component';
import { MaterialRequestItemComponent } from '@app/pages/material-requests/material-request-item/material-request-item.component';
import { TippyDirective } from '@ngneat/helipopper';
import { ServiceRequestItemComponent } from '@app/pages/service-requests/service-request-item/service-request-item.component';
import { IdentifierComponent } from './identifier/identifier.component';
import { ServiceRequestItemInputComponent } from '@app/pages/service-requests/service-request-item-input/service-request-item-input.component';
import { ServiceRequestItemOutputComponent } from '@app/pages/service-requests/service-request-item-output/service-request-item-output.component';

export function EmailValidator(
  control: AbstractControl
): ValidationErrors | null {
  return !control.value ||
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value)
    ? null
    : { email: true };
}
export function decimalPlacesValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value == null || value === '') {
    return null; // Don't validate if the value is null or empty
  }

  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(value) ? null : { decimalPlaces: true };
}
export function numericValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value == null || value === '') {
    return null; // Don't validate if the value is null or empty
  }

  const regex = /^\d+$/;
  return regex.test(value) ? null : { numeric: true };
}
export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}
@NgModule({
  declarations: [
    CustomFormComponent,
    OnboardingWrapperComponent,
    CostWrapperComponent,
    RequestLineItemComponent,
    ServiceRequestItemComponent,
    ServiceRequestItemInputComponent,
    ServiceRequestItemOutputComponent,
    QuoteLineItemComponent,
    PurchaseOrderItemComponent,
    InventoryInLineItemComponent,
    InventoryOutLineItemComponent,
    ProjectPersonnelWrapperComponent,
    ReservedInventoryItemComponent,
    FileValueAccessor,
    FileTypeComponent,
    LeaveBalanceItemComponent,
    CustomSearchSelectComponent,
    PurchaseRequestItemComponent,
    MaterialRequestItemComponent,
    IdentifierComponent
  ],
  imports: [
    DocsWrapperComponent,
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    TippyDirective,
    FormlyModule.forRoot({
      validators: [
        { name: 'email', validation: EmailValidator },
        { name: 'fieldMatch', validation: fieldMatchValidator },
        { name: 'decimalPlaces', validation: decimalPlacesValidator },
      ],
      validationMessages: [
        { name: 'email', message: 'Please enter a valid email address' },
        { name: 'required', message: 'This field is required' },
      ],
      wrappers: [
        { name: 'onboarding-wrapper', component: OnboardingWrapperComponent },
        { name: 'cost-wrapper', component: CostWrapperComponent },
      ],
      types: [
        { name: 'purchase_request_item', component: PurchaseRequestItemComponent },
        { name: 'material_request_item', component: MaterialRequestItemComponent },
        { name: 'repeat', component: RequestLineItemComponent },
        { name: 'inventory_out_request_item', component: RequestLineItemComponent },
        { name: 'quote', component: QuoteLineItemComponent },
        { name: 'purchase_order_quote', component: PurchaseOrderItemComponent },
        { name: 'inventory_in_item', component: InventoryInLineItemComponent },
        { name: 'service_request_item', component: ServiceRequestItemComponent },
        { name: 'inventory_out_item', component: InventoryOutLineItemComponent },
        { name: 'service_request_item_input', component: ServiceRequestItemInputComponent },
        { name: 'service_request_item_output', component: ServiceRequestItemOutputComponent },
        { name: 'reserved_inv_item', component: ReservedInventoryItemComponent },
        { name: 'purchase_item', component: PurchaseOrderItemComponent },
        { name: 'leave_balance_item', component: LeaveBalanceItemComponent },
        { name: 'project_personnel_wrapper', component: ProjectPersonnelWrapperComponent },
        {
          name: 'file',
          component: FileTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'customselect',
          component: CustomSearchSelectComponent,
          wrappers: ['form-field']
        },
        {
          name: 'identifier',
          component: IdentifierComponent,
          wrappers: ['form-field']
        }
      ],
    }),
    FormlyIonicModule,
  ],
  exports: [CustomFormComponent, DocsWrapperComponent],
})
export class CustomFormModule {}
