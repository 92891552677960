<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      Material Stock Checker
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="component-loader" *ngIf="formLoader">
  <ion-spinner name="lines"></ion-spinner>
  <ion-text color="medium" *ngIf="formLoader">Loading Materials ...</ion-text>
</div>
<ion-content class="ion-padding">
  <div class="form-wrapper" *ngIf="!formLoader" >
    <custom-form 
      #customForm
      (formSubmit)="getStockData($event)" 
      [formData]="formData"
      [formModel]="formModel" 
      [class]="'stock-checker-form'" 
      [submitButtonText]="'Check Stock'"
      [spinner]="formSubmitInProgress"
      [submitDisabled]="!optionsChanged">
    </custom-form>
  </div>
  <ion-grid *ngIf="dataLoaded" [ngClass]="{'outdated': optionsChanged}">
    <ion-row *ngIf="selectedMaterial">
      <ion-col>
        Showing results for Material: <strong>{{selectedMaterial.label}}, </strong>
        <span *ngIf="selectedProject">Project: {{selectedProject.label}}, </span>
        <span *ngIf="selectedMmid">Mmid: {{selectedMmid.label}}, </span>
        <span *ngIf="selectedVendor">Vendor: {{selectedVendor.label}}, </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="stock-checker-card">
          <p class="data">{{stock.stock.general + stock.stock.reserved + stock.stock.reserved_others}} {{stock.unit}}</p>
          <p class="label">available</p>
        </div>
      </ion-col>
      <ion-col>
        <div class="stock-checker-card">
          <p class="data">{{stock.expected.po + stock.expected.purr}} {{stock.unit}}</p>
          <p class="label">expected</p>
        </div>
      </ion-col>
      <ion-col>
        <div class="stock-checker-card">
          <p class="data">{{stock.required.matr + stock.required.invor}} {{stock.unit}}</p>
          <p class="label">required</p>
        </div>
      </ion-col>
    </ion-row>
    <div class="detail-content stock-checker-details">
      <ion-grid>
        <ion-row class="header-row">
          <ion-col size="6" class="ion-no-padding title">
            <h4>Available</h4>
          </ion-col>
          <ion-col class="ion-no-padding">

          </ion-col>
        </ion-row>

        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-row>
              <ion-col class="field-name" size="6">General Stock</ion-col>
              <ion-col class="field-value" size="3">{{stock.stock.general}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="6">Reserved <span  *ngIf="projectSelected">(this project)</span></ion-col>
              <ion-col class="field-value" size="3">{{stock.stock.reserved}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
            <ion-row *ngIf="projectSelected">
              <ion-col class="field-name" size="6">Reserved (other projects)</ion-col>
              <ion-col class="field-value" size="3">{{stock.stock.reserved_others}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
          </ion-col>
        </ion-row>


        <ion-row class="header-row">
          <ion-col size="6" class="ion-no-padding ion-padding-top title">
            <h4>Expected</h4>
          </ion-col>
          <ion-col class="ion-no-padding">

          </ion-col>
        </ion-row>
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-row>
              <ion-col class="field-name" size="6">Received and pending approval</ion-col>
              <ion-col class="field-value" size="3">{{stock.expected.received}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="6">From Purchase Orders</ion-col>
              <ion-col class="field-value" size="3">{{stock.expected.po}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="6">From Purchase requests</ion-col>
              <ion-col class="field-value" size="3">{{stock.expected.purr}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
          </ion-col>
        </ion-row>


        <ion-row class="header-row">
          <ion-col size="6" class="ion-no-padding ion-padding-top title">
            <h4>Required</h4>
          </ion-col>
          <ion-col class="ion-no-padding">

          </ion-col>
        </ion-row>

        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-row>
              <ion-col class="field-name" size="6">From Material Requests</ion-col>
              <ion-col class="field-value" size="3">{{stock.required.matr}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="6">From Inventory-out Requests</ion-col>
              <ion-col class="field-value" size="3">{{stock.required.invor}} {{stock.unit}}</ion-col>
              <!-- <ion-col class="field-value link clickable ion-text-right" size="3">Details</ion-col> -->
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-grid>
  <div class="cf-tow tow-1"></div>
  <div class="cf-tow tow-2"></div>
  <div class="cf-tow tow-3"></div>
  <div class="cf-tow tow-4"></div>
</ion-content>