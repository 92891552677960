import { Component, OnInit } from '@angular/core';
import { AlertsService } from '@app/global/services/alerts.service';
import { FieldArrayType } from '@ngx-formly/core';
import { MaterialRequestsService } from '@app/global/services/material-requests.service';
import { RequestsService } from '@app/global/services/requests.service';

@Component({
  selector: 'app-material-request-item',
  templateUrl: './material-request-item.component.html',
  styleUrls: ['./material-request-item.component.scss'],
})
export class MaterialRequestItemComponent extends FieldArrayType {
  advanced:number = -1;
  constructor(private alerts: AlertsService,
    private matReqServ: MaterialRequestsService,
    private reqServ: RequestsService
  ) {
    super();
  }
  async removeField(i: number) {
    const decision = await this.alerts.showPromisableAlert(
      'Delete Material',
      'Are you sure you want to remove this item from the request?',
      true,
      'Remove',
      'Cancel',
      'danger',
      'medium'
    );
    if (decision) {
      this.remove(i);
    }
  }
  toggleAdvanced(index:number){
    this.advanced = this.advanced === index ? -1 : index;
  }
  addItem(){
    this.saveDraft();
    this.add();
  }
  /**
   * On adding a new item, save the draft to local storage.
   * If the form is in edit mode, do not save the draft.
   * Project, Material, MMID and Vendor are saved as objects (source, name).
   * `source` instead of `id` is used to use same workflow as Edit.
   * @returns 
   */
  saveDraft(){
    if(this.matReqServ.isEditInstance || !this.matReqServ.formModel.material_request_items){
      return;
    }

    let draftData = JSON.parse(JSON.stringify(this.matReqServ.formModel)); // Deep copy
    // Remove undefined item in the array
    draftData.material_request_items = draftData.material_request_items.filter((item:any) => item?.material);
    if (draftData.material_request_items.length === 0) {
      return;
    }
    const _project:any = this.matReqServ.PROJECT_DROPDOWN_OPTIONS.find((project) => project.id === draftData.project);
    draftData.project = {id: _project.id, name: _project.name};
    for(let i=0; i<draftData.material_request_items?.length; i++){
      const item = draftData.material_request_items[i];
      if(item.material){
        const _material = this.matReqServ.MATERIAL_DROPDOWN_OPTIONS.find((material) => material.id === item.material);
        item.material = {source: _material?.id, name: _material?.name};
      }
      if (item.mmid) {
        const _mmid = this.reqServ.allMmidList.find((mmid:any) => mmid.id === item.mmid);
        item.mmid = {source: _mmid.id, name: _mmid.name};
      }
      if (item.vendor) {
        const _vendor = this.reqServ.allVendorList.find((vendor:any) => vendor.id === item.vendor);
        item.vendor = {source: _vendor.id, name: _vendor.name};
      }
    }
    localStorage.setItem('draft_material_request', JSON.stringify(draftData));
  }
}
