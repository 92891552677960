<ion-row *ngIf="inventoryInServ.selectedPo && inventoryInServ.selectedPo.id">
  <ion-col class="field-name ion-text-left" size="2">Vendor</ion-col>
  <ion-col class="field-value ion-text-left">{{inventoryInServ.selectedPo.vendor.name}}</ion-col>
</ion-row>
<ion-row *ngIf="inventoryInServ.selectedPo && inventoryInServ.selectedPo.id">
  <ion-col class="field-name ion-text-left" size="2">PO raised by</ion-col>
  <ion-col class="field-value ion-text-left">{{inventoryInServ.selectedPo.created_by.name}}</ion-col>
</ion-row>
<ion-row>
  <ion-col class="ion-text-left">
    <h4>{{props.label}}</h4>
  </ion-col>
</ion-row>
<ion-row *ngFor="let poItem of field.fieldGroup; let i = index" >
  <ion-col>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-text-left">
        <ion-text color="medium">Item {{ i + 1 }}</ion-text>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-button *ngIf="!inventoryInServ.isEditInstance" (click)="removeItem(i)" color="danger" fill="clear">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="quote-item">
      <ion-row *ngIf="inventoryInServ.selectedPoItem.length && inventoryInServ.selectedPoItem[i].id" class="ion-no-padding read-only-details">
        <ion-col class="ion-no-padding left-section">
          <ion-row>
            <ion-col class="section-title ion-text-left">Purchase Order Item Details
            </ion-col>
          </ion-row>
          <!-- MATERIAL INVENTORY-IN -->
          <div *ngIf="inventoryInServ.selectedPoItem[i]?.purchase_request_item">
            <ion-row>
              <ion-col class="field-name" size="5">MMID</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryInServ.selectedPoItem[i].mmid.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Material</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryInServ.selectedPoItem[i].mmid.material.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Manufacturer</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryInServ.selectedPoItem[i].mmid.manufacturer.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Quantity</ion-col>
              <ion-col class="field-value ion-text-left">
                {{inventoryInServ.selectedPoItem[i].purchase_request_item.quantity}}
                {{inventoryInServ.selectedPoItem[i].mmid.unit}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Expected Delivery Date</ion-col>
              <ion-col class="field-value ion-text-left">
                {{inventoryInServ.selectedPoItem[i].purchase_request_item.expected_delivery_date | date: 'mediumDate'}}</ion-col>
            </ion-row>
          </div>
          <!-- PART INVENTORY-IN -->
          <div *ngIf="inventoryInServ.selectedPoItem[i]?.service_request_item">
            <ion-row>
              <ion-col class="field-name" size="5">Part</ion-col>
              <ion-col class="field-value clickable link ion-text-left">{{inventoryInServ.selectedOutputs[i].part.name}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Quantity</ion-col>
              <ion-col class="field-value ion-text-left">
                {{inventoryInServ.selectedOutputs[i].quantity}}
                {{inventoryInServ.selectedOutputs[i].part.unit}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="field-name" size="5">Expected Delivery Date</ion-col>
              <ion-col class="field-value ion-text-left">
                {{inventoryInServ.selectedPoItem[i].service_request_item.expected_delivery_date | date: 'mediumDate'}}</ion-col>
            </ion-row>
          </div>
          <ion-row>
            <ion-col class="section-title ion-text-left">Received Item Details</ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">Received</ion-col>
            <ion-col *ngIf="inventoryInServ.selectedPoItem[i]?.purchase_request_item" class="field-value ion-text-left">
                {{inventoryInServ.selectedPoItem[i]?.receipt_status[0]?.received}} {{inventoryInServ.selectedPoItem[i].mmid.unit}}
            </ion-col>
            <ion-col *ngIf="inventoryInServ.selectedPoItem[i]?.service_request_item" class="field-value ion-text-left">
                <div *ngFor="let receipt_status of inventoryInServ.selectedPoItem[i]?.receipt_status">
                  <div *ngIf="receipt_status.part.source === inventoryInServ.selectedOutputs[i].part.source">
                    {{receipt_status?.received}} {{receipt_status.unit}}
                  </div>
                </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">Requestor Approved</ion-col>
            <ion-col *ngIf="inventoryInServ.selectedPoItem[i]?.purchase_request_item" class="field-value ion-text-left">
              {{inventoryInServ.selectedPoItem[i]?.receipt_status[0]?.requestor_approved}} {{inventoryInServ.selectedPoItem[i].mmid.unit}}
            </ion-col>
            <ion-col *ngIf="inventoryInServ.selectedPoItem[i]?.service_request_item" class="field-value ion-text-left">
              <div *ngFor="let receipt_status of inventoryInServ.selectedPoItem[i]?.receipt_status">
                <div *ngIf="receipt_status.part.source === inventoryInServ.selectedOutputs[i].part.source">
                  {{receipt_status?.requestor_approved}} {{receipt_status.unit}}
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="field-name" size="5">QC Approved</ion-col>
            <ion-col *ngIf="inventoryInServ.selectedPoItem[i]?.purchase_request_item" class="field-value ion-text-left">
              {{inventoryInServ.selectedPoItem[i]?.receipt_status[0]?.qc_approved}} {{inventoryInServ.selectedPoItem[i].mmid.unit}}
            </ion-col>
            <ion-col *ngIf="inventoryInServ.selectedPoItem[i]?.service_request_item" class="field-value ion-text-left">
              <div *ngFor="let receipt_status of inventoryInServ.selectedPoItem[i]?.receipt_status">
                <div *ngIf="receipt_status.part.source === inventoryInServ.selectedOutputs[i].part.source">
                  {{receipt_status?.qc_approved}} {{receipt_status.unit}}
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-col>
        <formly-field [field]="poItem"></formly-field>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<div class="formly-form-item-float-button">
  <ion-button [disabled]="!isPoSelected || inventoryInServ.isEditInstance" (click)="addItem()" color="success">Add Item</ion-button>
</div>