<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" class="mobile-only">
      <ion-button fill="clear" slot="start" color="light" (click)="toggleMenu()">
        <ion-icon slot="icon-only" name="menu"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" slot="end" color="light" (click)="showStockChecker()">
        <ion-icon slot="icon-only" name="calculator"></ion-icon>
      </ion-button>

      <ion-button fill="clear" slot="end" color="light" (click)="goToInbox()" class="notification-button">
        <ion-icon slot="icon-only" name="mail"></ion-icon>
        <div class="notif-count" *ngIf="notifServ.notifications.count">
          {{notifServ.notifications.count}}
        </div>
      </ion-button>

      <ion-button fill="clear" slot="end" color="light">
        <ion-icon slot="icon-only" name="settings"></ion-icon>
      </ion-button>
      <ion-button id="logout-button" fill="clear" slot="end" color="light" (click)="logout()">
        Logout
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="fillet">
  <div class="inner"></div>
</div>